import React, { useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import { Heading2, sectionSm } from "@styles/ui"
import PatientVoice from "@components/PatientVoice"
import PrimaryCta from "@components/PrimaryCta"
import VideoGallery from "@components/videoGallery"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Anchor from "@components/Anchor"

// Video gallery gifs

import standupChampionsLogo from "@images/logos/logo-sucs.svg"
import brookeHeading from "@images/standup-champions/brooke-title.svg"
import oscarHeading from "@images/standup-champions/oscar-title.svg"
import graceHeading from "@images/standup-champions/grace-title.svg"
import ginaHeading from "@images/standup-champions/gina-title.svg"

import gifGraceThumb1 from "@images/standup-champions/grace-video-1.gif"
import gifGraceThumb2 from "@images/standup-champions/grace-video-2.gif"
import gifGraceThumb3 from "@images/standup-champions/grace-video-3.gif"
import gifGraceThumb4 from "@images/standup-champions/grace-video-4.gif"
import gifGraceThumb5 from "@images/standup-champions/grace-video-5.gif"
import gifBrookeThumb1 from "@images/standup-champions/brooke-video-1.gif"
import gifBrookeThumb2 from "@images/standup-champions/brooke-video-2.gif"
import gifBrookeThumb3 from "@images/standup-champions/brooke-video-3.gif"
import gifBrookeThumb4 from "@images/standup-champions/brooke-video-4.gif"
import gifOscarThumb1 from "@images/standup-champions/oscar-video-1.gif"
import gifOscarThumb2 from "@images/standup-champions/oscar-video-2.gif"
import gifOscarThumb3 from "@images/standup-champions/oscar-video-3.gif"
import videoGifThumb1 from "@images/standup-champions/gina-clip-1.mp4"
import videoGifThumb2 from "@images/standup-champions/gina-clip-2.mp4"
import videoGifThumb3 from "@images/standup-champions/gina-clip-3.mp4"

const PatientStoriesPage = ({ location, data }) => {
  const [currentGallery, setCurrentGallery] = useState(null)

  const patientVoiceBrookeData = {
    patientVoice: data.patientVoiceBrooke,
    patientVoiceMobile: data.patientVoiceBrookeMobile,
  }

  const anchorLinks = [
    { label: "Grace", link: "#grace" },
    { label: "Gina", link: "#gina" },
    { label: "Brooke", link: "#brooke" },
    { label: "Oscar", link: "#oscar" },
  ]

  const videoGalleryGrace = [
    {
      wistiaId: "eygrtzfz01",
      title: (
        <>
          Grace discusses her
          <br />
          diagnosis
        </>
      ),
      description:
        "Watch Grace discuss when she was diagnosed and how she felt before starting treatment.",
      thumbnail: data.vidGrace1,
      thumbnailSmall: data.vidGrace1Small,
      gifSrc: gifGraceThumb1,
      alt: "Watch video: Grace discusses her diagnosis",
      id: 0,
    },
    {
      wistiaId: "jh9qkp4xby",
      title: (
        <>
          Grace talks with her
          <br />
          doctor
        </>
      ),
      description:
        "Hear Grace talk about having a discussion with her doctor about treatment options.",
      thumbnail: data.vidGrace2,
      thumbnailSmall: data.vidGrace2Small,
      gifSrc: gifGraceThumb2,
      alt: "Watch video: Grace talks with her doctor",
      id: 1,
    },
    {
      wistiaId: "c0n9xke4im",
      title: (
        <>
          Grace&prime;s experience
          <br />
          taking XYWAV
        </>
      ),
      description:
        "Listen to Grace as she reveals her XYWAV experience, from starting treatment to how it’s going now.",
      thumbnail: data.vidGrace3,
      thumbnailSmall: data.vidGrace3Small,
      gifSrc: gifGraceThumb3,
      alt: "Watch video: Grace's experience taking XYWAV",
      id: 2,
    },
    {
      wistiaId: "nt494qfoch",
      title: (
        <>
          Grace&prime;s nighttime
          <br />
          routine with XYWAV
        </>
      ),
      description:
        "Find out what Grace has to say about her nighttime routine and how she builds it around XYWAV.",
      thumbnail: data.vidGrace4,
      thumbnailSmall: data.vidGrace4Small,
      gifSrc: gifGraceThumb4,
      alt: "Watch video: Grace's nighttime routine with XYWAV",
      id: 3,
    },
    {
      wistiaId: "hz03k7d26o",
      title: (
        <>
          Grace&prime;s experience with JazzCares<sup>&reg;</sup>
        </>
      ),
      description:
        "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV.",
      thumbnail: data.vidGrace5,
      thumbnailSmall: data.vidGrace5Small,
      gifSrc: gifGraceThumb5,
      alt: "Watch video: Grace's experience with JazzCares®",
      id: 4,
    },
  ]

  const videoGalleryGina = [
    {
      wistiaId: "wolmgpnmb6",
      title: (
        <>
          Gina shares her
          <br />
          journey to diagnosis
        </>
      ),
      description:
        "Watch Gina talk about the time when she was finally able to put a name to her symptoms of cataplexy and excessive daytime sleepiness",
      thumbnail: data.vidGina1,
      thumbnailSmall: data.vidGina1Small,
      videoGifSrc: videoGifThumb1,
      alt: "Watch a video on Gina's excessive daytime sleepiness symptoms.  ",
      id: 0,
    },
    {
      wistiaId: "namp2arog6",
      title: "Gina discusses sodium intake with her doctor",
      description:
        "Find out why Gina talked with her doctor about the sodium content of her medication.",
      thumbnail: data.vidGina2,
      thumbnailSmall: data.vidGina2Small,
      videoGifSrc: videoGifThumb2,
      alt: "Watch a video on Gina's sodium intake and how that can affect treatment choices.",
      id: 1,
    },
    {
      wistiaId: "krylp6n944",
      title: (
        <>
          Gina&prime;s treatment with
          <br />
          XYWAV
        </>
      ),
      description:
        "Hear how Gina and her doctor found her individualized dose of XYWAV and the importance of her nighttime routine.",
      thumbnail: data.vidGina3,
      thumbnailSmall: data.vidGina3Small,
      videoGifSrc: videoGifThumb3,
      alt: "Watch a video on determining the best dosage for Gina.",
      id: 2,
    },
  ]

  const videoGalleryBrooke = [
    {
      wistiaId: "k3uye4zoh3",
      title: (
        <>
          Brooke&prime;s journey to
          <br />
          diagnosis
        </>
      ),
      description:
        "Watch Brooke talk about her journey to diagnosis and starting treatment with XYWAV.",
      thumbnail: data.vidBrooke1,
      thumbnailSmall: data.vidBrooke1Small,
      gifSrc: gifBrookeThumb1,
      alt: "Watch video: Brooke's journey to diagnosis",
      id: 0,
      metadata: {
        name: "Grace's Experience With JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=hz03k7d26o",
        duration: "PT6M21S",
      },
    },
    {
      wistiaId: "ch2cf95fak",
      title: (
        <>
          Brooke discusses
          <br />
          sodium content
        </>
      ),
      description:
        "Listen to Brooke discuss why having lower sodium content is important to her.",
      thumbnail: data.vidBrooke2,
      thumbnailSmall: data.vidBrooke2Small,
      gifSrc: gifBrookeThumb2,
      alt: "Watch video: Brooke discusses sodium content",
      id: 1,
    },
    {
      wistiaId: "3h8myw6qnd",
      title: (
        <>
          Brooke&prime;s nighttime
          <br />
          routine with XYWAV
        </>
      ),
      description:
        "Hear from Brooke about how XYWAV has become an important part of her nighttime routine.",
      thumbnail: data.vidBrooke3,
      thumbnailSmall: data.vidBrooke3Small,
      gifSrc: gifBrookeThumb3,
      alt: "Watch video: Brooke's nighttime routine with XYWAV",
      id: 2,
    },
    {
      wistiaId: "elmp5009xf",
      title: (
        <>
          Brooke discusses
          <br />
          JazzCares<sup>&reg;</sup>
        </>
      ),
      description:
        "Find out how Brooke gets support every step of her treatment journey with JazzCares.",
      thumbnail: data.vidBrooke4,
      thumbnailSmall: data.vidBrooke4Small,
      gifSrc: gifBrookeThumb4,
      alt: "Watch video: Brooke discusses JazzCares®",
      id: 3,
    },
  ]

  const videoGalleryOscar = [
    {
      wistiaId: "y7bntnqdi2",
      title: <>Oscar&prime;s journey to diagnosis and treatment</>,
      description:
        "Hear from Oscar about how he was first diagnosed and how he finally found treatment with XYWAV.",
      thumbnail: data.vidOscar1,
      thumbnailSmall: data.vidOscar1Small,
      gifSrc: gifOscarThumb1,
      alt: "Watch video: Oscar's journey to diagnosis and treatment",
      id: 0,
    },
    {
      wistiaId: "ewodm1jozj",
      title: (
        <>
          How Oscar prioritizes
          <br />
          his health
        </>
      ),
      description:
        "Listen to Oscar discuss his morning and lunchtime routines and how he prioritizes his health.",
      thumbnail: data.vidOscar2,
      thumbnailSmall: data.vidOscar2Small,
      gifSrc: gifOscarThumb2,
      alt: "Watch video: How Oscar prioritizes his health",
      id: 1,
    },
    {
      wistiaId: "d5lk6iomzx",
      title: <>Oscar&prime;s evening routine with XYWAV</>,
      description:
        "Watch Oscar talk about his evening routine, how he adjusts it when necessary, and how he takes his XYWAV doses.",
      thumbnail: data.vidOscar3,
      thumbnailSmall: data.vidOscar3Small,
      gifSrc: gifOscarThumb3,
      alt: "Watch video: Oscar's evening routine with XYWAV",
      id: 2,
    },
  ]

  const videoMetaData = [
    {
      metadata: {
        name: "Grace Discusses Her Diagnosis | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Grace discuss when she was diagnosed. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=eygrtzfz01",
        duration: "PT6M30S",
      },
    },
    {
      metadata: {
        name: "Grace Talks With Her Doctor | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear Grace talk about having a discussion with her doctor about treatment options. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=jh9qkp4xby",
        duration: "PT7M3S",
      },
    },
    {
      metadata: {
        name: "Grace's Experience Taking XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Grace as she reveals her XYWAV experience. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=c0n9xke4im",
        duration: "PT 6M8S",
      },
    },
    {
      metadata: {
        name: "Grace's Nighttime Routine With XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Find out what Grace has to say about her nighttime routine and how she builds it around XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=nt494qfoch",
        duration: "PT6M21S",
      },
    },
    {
      metadata: {
        name: "Grace's Experience With JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the support that JazzCares offers Grace throughout her treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidGrace5.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=hz03k7d26o",
        duration: "PT7M2S",
      },
    },
    {
      metadata: {
        name: "Brooke's Journey to Diagnosis | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Brooke talk about her journey to diagnosis and starting treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=k3uye4zoh3",
        duration: "PT7M31S",
      },
    },
    {
      metadata: {
        name: "Brooke Discusses Sodium Content | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Brooke discuss why having lower sodium content is important to her. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=ch2cf95fak",
        duration: "PT6M15S",
      },
    },
    {
      metadata: {
        name: "Brooke's Nighttime Routine With XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear from Brooke about how XYWAV has become an important part of her nighttime routine. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=3h8myw6qnd",
        duration: "PT7M21S",
      },
    },
    {
      metadata: {
        name: "Brooke Discusses JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Find out how Brooke gets support every step of her treatment journey with JazzCares. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidBrooke4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=elmp5009xf",
        duration: "PT7M29S",
      },
    },
    {
      metadata: {
        name: "Narcolepsy Diagnosis Journey | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Hear from Oscar about how he was first diagnosed and how he finally found treatment with XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar1.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=y7bntnqdi2",
        duration: "PT6M30S",
      },
    },
    {
      metadata: {
        name: "Morning and Lunchtime Routine With Narcolepsy | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Listen to Oscar discuss his morning and lunchtime routines and how he prioritizes his health. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar2.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=ewodm1jozj",
        duration: "PT6M32S",
      },
    },
    {
      metadata: {
        name: "Evening Routine With Narcolepsy | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Watch Oscar talk about his evening routine, how he adjusts it when necessary, and how he takes his XYWAV doses. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidOscar3.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=d5lk6iomzx",
        duration: "PT7M59S",
      },
    },
  ]

  const heroImage = withArtDirection(getImage(data.heroMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(data.hero),
    },
  ])

  return (
    <Layout
      type="narcolepsy"
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2400057 Rev0424</nobr>
        </>
      }
    >
      <Seo
        title="Narcolepsy Patient Stories and Testimonials | XYWAV®"
        description="Hear from patients living with narcolepsy. Learn about their challenges and successes as they navigate their symptoms, diagnosis, and treatment journeys."
        location={location}
        videoMetadata={videoMetaData}
      />

      <section tw="relative overflow-hidden md:(grid min-h-[520px])">
        <div tw="relative md:(absolute top-0 right-0 grid grid-cols-12 justify-items-end overflow-hidden)">
          <div tw="relative overflow-hidden md:(col-span-8 col-start-5)">
            <GatsbyImage
              tw="md:(h-[520px]) md-lg:(w-[150%] [img]:absolute left-[-200px]) "
              image={heroImage}
              loading="eager"
              alt={""}
              imgStyle={{
                width: "100%",
                height: "100%",
              }}
            />
            <div tw="mobile-narc-home-hero-gradient absolute left-0 bottom-0 w-screen h-[200px] block md:(narc-home-hero-gradient top-0 h-[520px] w-[530px])"></div>
          </div>
        </div>
        <Container>
          <div tw="-mt-[66px] relative pt-5 pb-4 md:(mt-0 py-0 grid h-full grid-cols-12 overflow-hidden)">
            <div tw="col-span-8 md:(flex items-center justify-start)">
              <div tw="md:(max-w-[700px])">
                <h1 tw="pl-0.5 text-[12px] uppercase font-avenir font-black tracking-[1px] md:(text-4xl pl-1 mb-3)">
                  Real patients, <br tw="md:(hidden)" />
                  Real stories, <br tw="md:(hidden)" />
                  Real inspiration
                </h1>
                <img
                  src={standupChampionsLogo}
                  alt=""
                  tw="mt-[15px] md:(mt-[35px] w-[750px] ml-[110px])"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Main content */}
      <section css={sectionSm}>
        <Container>
          <div tw="md:(grid grid-cols-12 gap-x-9 items-center)">
            <h2
              css={[
                Heading2,
                tw`md:(col-span-6)`,
                tw`before:(block w-[80px] mb-7.5 h-1.5 rounded-md bg-small-before-gradient) xl:(before:mb-5)`,
              ]}
            >
              Standup Champions are people who take XYWAV and want to share
              their successes and challenges to inspire others with their
              narcolepsy journey
            </h2>

            <p tw="mt-[15px] md:(mt-0 col-span-6)">
              Whether they&apos;re sharing a full day in their lives or offering
              a few words of encouragement, our Standup Champions want to help
              other people who have cataplexy and/or excessive daytime
              sleepiness (EDS) in narcolepsy with the knowledge they&apos;ve
              gained through their diagnosis and treatment journeys.
            </p>
          </div>
          <div tw="md:w-[490px] mx-auto pt-12">
            <div tw="mx-auto block w-[80px] mb-7 h-1.5 rounded-md bg-small-before-gradient"></div>
            <div tw="flex flex-col gap-y-4 items-center md:(justify-between flex-row)">
              <p tw="font-black">Hear From:</p>
              {anchorLinks.map((link, index) => {
                return (
                  <div key={index}>
                    <Anchor link={link.link} addedStyles={tw`font-extrabold`}>
                      {link.label}
                    </Anchor>
                  </div>
                )
              })}
            </div>
          </div>
        </Container>
      </section>

      <section tw="bg-lilac py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={graceHeading}
              alt=""
              tw="w-[158px] mx-auto mb-[15px] md:(h-full mb-[35px])"
            />
            <p tw="text-center">
              Get to know Grace and hear about her journey with narcolepsy
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryGrace}
            location={location}
            sectionId="grace"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="1"
          />
        </Container>
      </section>

      <PatientVoice
        quote={
          <>
            I would definitely recommend to others who are struggling with
            excessive daytime sleepiness and cataplexy in narcolepsy to talk to
            their doctor about trying XYWAV to see if it may help them like it
            helped me.&rdquo;
          </>
        }
        patient={"Grace"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        disclaimer={"Compensated for her time. Results may vary."}
        imageRight
        mdLgImagePosition={tw`[img]:md-lg:!object-[150px top]`}
        mdLgGradientLeftPosition={tw`md-lg:left-[149px]`}
      />

      <section tw="bg-lilac py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={ginaHeading}
              alt=""
              tw="w-[158px] mx-auto mb-[15px] md:(h-full mb-[35px])"
            />
            <p tw="text-center">
              Hear from Gina about how she navigates living with narcolepsy and
              her experience taking XYWAV
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryGina}
            location={location}
            sectionId="gina"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="2"
          />
        </Container>
      </section>

      <section tw="bg-lilac py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={brookeHeading}
              alt=""
              tw="w-[158px] mx-auto mb-[15px] md:(h-full mb-[35px])"
            />
            <p tw="text-center">
              Meet Brooke and find out what life with narcolepsy is like for her
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryBrooke}
            location={location}
            sectionId="brooke"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="3"
          />
        </Container>
      </section>

      <PatientVoice
        quote={
          <>
            As someone who relies on medication, it was challenging for me to
            cope with the uncertainty of things like health insurance coverage.
            But JazzCares has supported me along the way, from prescription
            deliveries to managing copays.&rdquo;
          </>
        }
        patient={"Brooke"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={patientVoiceBrookeData}
        imageRight
        mdLgImagePosition={tw`[img]:md-lg:!object-[60px top]`}
        mdLgGradientLeftPosition={tw`md-lg:left-[59px]`}
        disclaimer={"Compensated for her time. Results may vary."}
      />

      <section tw="bg-lilac py-[30px] md:py-[50px]">
        <Container>
          <div tw="pb-[50px]">
            <img
              src={oscarHeading}
              alt=""
              tw="w-[158px] mx-auto mb-[15px] md:(h-full mb-[35px])"
            />
            <p tw="text-center">
              Meet Oscar and learn about his narcolepsy diagnosis journey
            </p>
          </div>
          <VideoGallery
            theme={"suc"}
            videos={videoGalleryOscar}
            location={location}
            sectionId="oscar"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="4"
          />
        </Container>
      </section>

      <section tw="pt-[30px] md:pt-[50px]">
        <Container>
          <h2
            css={[
              Heading2,
              tw`text-center mb-2.5`,
              tw`mb-6 mx-3 text-center before:(block mb-6 h-1.5 rounded-md bg-small-before-gradient mx-auto w-[100px] lg:w-[124px])`,
            ]}
          >
            Interested in sharing your journey with others who are struggling
            with narcolepsy?
          </h2>
          <div tw="mx-auto my-[15px] flex justify-center mb-[30px]">
            <PrimaryCta url="/become-a-standup-champion/">
              Submit your story
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PatientStoriesPage

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "standup-champions/sup-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 912, placeholder: NONE)
      }
    }
    heroMobile: file(
      relativePath: { eq: "standup-champions/sup-hero-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "talk-to-your-doctor/grace-desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "talk-to-your-doctor/grace-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    vidGrace1: file(relativePath: { eq: "video-thumbnails/grace-1.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace1Small: file(relativePath: { eq: "video-thumbnails/grace-1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace2: file(relativePath: { eq: "video-thumbnails/grace-2.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace2Small: file(relativePath: { eq: "video-thumbnails/grace-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace3: file(relativePath: { eq: "video-thumbnails/grace-3.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace3Small: file(relativePath: { eq: "video-thumbnails/grace-3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace4: file(relativePath: { eq: "video-thumbnails/grace-4.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace4Small: file(relativePath: { eq: "video-thumbnails/grace-4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGrace5: file(relativePath: { eq: "video-thumbnails/grace-5.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGrace5Small: file(relativePath: { eq: "video-thumbnails/grace-5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke1: file(relativePath: { eq: "video-thumbnails/brooke-1.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke1Small: file(
      relativePath: { eq: "video-thumbnails/brooke-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke2: file(relativePath: { eq: "video-thumbnails/brooke-2.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke2Small: file(
      relativePath: { eq: "video-thumbnails/brooke-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke3: file(relativePath: { eq: "video-thumbnails/brooke-3.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke3Small: file(
      relativePath: { eq: "video-thumbnails/brooke-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidBrooke4: file(relativePath: { eq: "video-thumbnails/brooke-4.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidBrooke4Small: file(
      relativePath: { eq: "video-thumbnails/brooke-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar1: file(relativePath: { eq: "video-thumbnails/oscar-1.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar1Small: file(relativePath: { eq: "video-thumbnails/oscar-1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar2: file(relativePath: { eq: "video-thumbnails/oscar-2.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar2Small: file(relativePath: { eq: "video-thumbnails/oscar-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidOscar3: file(relativePath: { eq: "video-thumbnails/oscar-3.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidOscar3Small: file(relativePath: { eq: "video-thumbnails/oscar-3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    patientVoiceBrooke: file(
      relativePath: { eq: "standup-champions/brooke-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 425, placeholder: NONE)
      }
    }
    patientVoiceBrookeMobile: file(
      relativePath: { eq: "standup-champions/brooke-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    vidGina1: file(
      relativePath: {
        eq: "video-thumbnails/gina-understanding-narcolepsy-symptoms.jpg"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina1Small: file(
      relativePath: {
        eq: "video-thumbnails/gina-understanding-narcolepsy-symptoms.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGina2: file(
      relativePath: { eq: "video-thumbnails/gina-sodium-intake.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina2Small: file(
      relativePath: { eq: "video-thumbnails/gina-sodium-intake.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidGina3: file(
      relativePath: { eq: "video-thumbnails/gina-dosing-titration.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidGina3Small: file(
      relativePath: { eq: "video-thumbnails/gina-dosing-titration.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
  }
`
